<template>
  <div class="page-container inventory-page">
    <portal to="page-name">Inventário de produtos</portal>
    <div class="loading loading-lg mt-2" v-if="loading"></div>
    <div class="card card-page" v-else>
      <div class="card-header">
        <div class="card-title">Inventário de produtos</div>
      </div>
      <div class="card-body">
        <div v-if="finished">
          <small class="text-info">* Este inventário já foi finalizado</small>
          <div class="item-group">
            <h6 class="h6">Dados do inventário</h6>
            <div class="columns">
              <div class="column col-6 col-md-12 col-sm-12">
                <label class="form-label text-bold">Data</label>
                {{ form.date | date('DD/MM/YYYY HH:mm') }}
              </div>
              <div class="column col-6 col-md-12 col-sm-12">
                <label class="form-label text-bold">Responsável</label>
                {{ user.name }}
              </div>
            </div>
          </div>
          <div class="item-group">
            <h6 class="h6">Produtos</h6>
            <div class="card-detail-data">
              <table class="table">
                <thead>
                <tr>
                  <th class="hide-sm" style="width:40px">#</th>
                  <th>Produto</th>
                  <th class="text-center">Unidade</th>
                  <th class="text-center">Lote</th>
                  <th class="text-center">Validade</th>
                  <th class="text-center">Qtde. anterior</th>
                  <th class="text-center">Nova qtde.</th>
                  <th class="text-right">Valor unitário</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in form.products" :key="i">
                  <td class="hide-sm">{{ i + 1 }}</td>
                  <td>{{ item.name }}</td>
                  <td class="text-center">{{ measure.getName(item.outputUnit) }}</td>
                  <td class="text-center">{{ item.lot ? item.lot.name : '' }}</td>
                  <td class="text-center">{{ item.lot ? item.lot.validity : '' | date }}</td>
                  <td class="text-center">
                    {{ item.oldValues.quantity | number(3) }}
                  </td>
                  <td class="text-center">
                    {{ item.newValues.quantity | number(3) }}
                  </td>
                  <td class="text-right">
                    {{ item.newValues.value | currency }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="columns">
            <div class="column col-3 col-md-6 col-sm-12 form-group">
              <label class="form-label">Data</label>
              <strong>{{ form.date | date('datetime') }}</strong>
            </div>
            <div class="column col-5 col-md-6 col-sm-12 form-group">
              <label class="form-label">Responsável</label>
              <strong>{{ user.name }}</strong>
            </div>
          </div>
          <div class="divider" />
          <div class="columns">
            <div class="column col-6 col-md-12 col-sm-12 form-group"
                 :class="{'has-error': $v.form.placeId.$error}">
              <label class="form-label">Local</label>
              <select id="place" class="form-select"
                      v-model="form.placeId"
                      @blur="$v.form.placeId.$touch()"
                      :disabled="product || form.products.length > 0">
                <option value="">[Selecione]</option>
                <option v-for="item in places"
                        :value="item.id" :key="item.id">{{ item.name }}</option>
              </select>
              <template v-if="$v.form.placeId.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.placeId.required">Campo obrigatório</div>
              </template>
            </div>
            <div class="column col-6 col-md-12 col-sm-12 form-group">
              <label class="form-label">Grupo</label>
              <select id="place" class="form-select"
                      v-model="form.groupId"
                      :disabled="form.products.length > 0">
                <option value="">[Todos os grupos]</option>
                <option v-for="item in groups"
                        :value="item.id" :key="item.id">{{ item.name }}</option>
              </select>
            </div>
            <div class="column form-group">
              <label class="form-label">Produto</label>
              <dx-autocomplete
                id="product"
                ref="productAutocomplete"
                v-model="product"
                :source="findProduct"
                :disabled="!!product || !form.placeId || form.products.length > 0"
                track-by="id"
                label="name"
                @select="selectProduct"
                placeholder="Nome ou código do produto"
              >
                <button slot="action" @click="unsetProduct"
                        class="btn btn-action input-group-btn btn-icon"
                        :class="product ? 'btn-gray' : 'btn-neutral'"
                        :disabled="!form.placeId || form.products.length > 0"
                        tabindex="-1">
                  <fa-icon :icon="['fal', product ? 'times' : 'search']"></fa-icon>
                </button>
                <template v-slot="{ item }">{{ item.name }}</template>
              </dx-autocomplete>
            </div>
            <div class="column col-auto mb-2" style="display: flex; align-items: flex-end">
              <button class="btn btn-icon btn-icon-left btn-primary"
                      @click="search" v-if="form.products.length === 0">
                <fa-icon :icon="['fal', 'search']" />Pesquisar
              </button>
              <button class="btn btn-icon btn-icon-left btn-gray"
                      @click="cancel" v-else>
                <fa-icon :icon="['fal', 'times']" />Cancelar
              </button>
            </div>
<!--            <div class="column col-auto mb-2" style="display: flex; align-items: flex-end">-->
<!--              <button class="btn btn-icon btn-icon-left btn-gray" @click="openNewLot"-->
<!--                      :disabled="!product || !product.manageLot">-->
<!--                <fa-icon :icon="['fal', 'plus']" />Adicionar lote-->
<!--              </button>-->
<!--            </div>-->
          </div>
          <div class="loading loading-lg" v-if="productLoading" />
          <template v-else>
            <div class="empty" v-if="form.products.length === 0">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
              </div>
              <p class="empty-title h6">Produtos</p>
              <p class="empty-subtitle">
                Nenhum produto encontrado. Verifique os filtros e tente novamente
              </p>
            </div>
            <template v-else>
              <div class="item-group" v-for="(item, i) in form.products" :key="i">
                <div class="columns">
                  <div class="column text-bold centered">{{ item.name }}</div>
                  <div class="column col-auto col-sm-12 centered">
                    <button class="btn btn-sm btn-action btn-icon btn-gray-outline"
                            @click="removeItem(item.id, i)"
                            v-if="item.isNew">
                      <fa-icon :icon="['fal', 'times']"/>
                    </button>
                  </div>
                </div>
                <div class="divider"></div>
                <div class="columns">
                  <div class="column col-3 col-md-6 col-sm-12 form-group">
                    <small class="form-label">Lote</small>
                    <input type="text" class="form-input text-bold text-info input-sm"
                           :class="{'is-error': $v.form.products.$each[i].lot.name.$error}"
                           v-model="item.lot.name"
                           :readonly="!item.isNew"
                           v-if="item.manageLot">
                    <input type="text" class="form-input input-sm"
                           value="Sem lote"
                           disabled
                           v-else>
                  </div>
                  <div class="column col-2 col-md-6 col-sm-12 form-group">
                    <small class="form-label">Validade</small>
                    <dx-input-date class="form-input input-sm text-center"
                                   :class="{'is-error': $v.form.products.$each[i]
                                   .lot.validity.$error}"
                                   v-model="item.lot.validity"
                                   v-if="item.manageLot" />
                    <input type="text" class="form-input input-sm"
                           value="Sem validade"
                           disabled
                           v-else>
                  </div>
                  <div class="column col-sm-12 form-group">
                    <small class="form-label">Qtde. atual</small>
                    <dx-input-number v-model="item.oldValues.quantity"
                                     :disabled="item.lock"
                                     readonly="true"
                                     class="form-input input-sm text-center"
                                     :precision="3" />
                  </div>
                  <div class="column col-sm-12 form-group">
                    <small class="form-label">Nova qtde.</small>
                    <dx-input-number v-model="item.newValues.quantity"
                                     class="form-input input-sm text-center"
                                     :class="{'is-error': $v.form.products.$each[i]
                                     .newValues.quantity.$error}"
                                     :precision="3" maxlength="11" />
                  </div>
                  <div class="column col-sm-12 form-group">
                    <small class="form-label">Custo médio</small>
                    <dx-input-number v-model="item.newValues.value"
                                     class="form-input input-sm text-right"
                                     :class="{'is-error': $v.form.products.$each[i]
                                     .newValues.value.$error}"
                                     :precision="2" maxlength="11" />
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1"
                v-if="!finished"
                @click="save(false)"
                :disabled="finishing || saving"
                :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn btn-warning mr-1"
                v-if="!finished"
                @click="save(true)"
                :disabled="finishing || saving || form.products.length === 0"
                :class="{loading: finishing}">
          Finalizar
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import * as measure from 'src/data/measure-units';
import moment from 'moment';
import { mapState } from 'vuex';
import { mergeFrom } from '@/helpers/object';
import { FINISH_INVENTORY } from '@/data/actions/modules/stock';
import { minValue, required } from 'vuelidate/lib/validators';
import { date, minDate } from '../../../data/validators';
import formMixin from '../../../mixins/form';

export default {
  mixins: [formMixin],
  data() {
    return {
      measure,
      isNew: false,
      loading: false,
      saving: false,
      finishing: false,
      productLoading: false,
      product: null,
      places: [],
      groups: [],
      form: this.blankForm(),
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    finished() {
      return this.form.status === 'finished';
    },
    canAccessFinishKit() {
      if (FINISH_INVENTORY) {
        return this.$can(FINISH_INVENTORY);
      }
      return true;
    },
  },
  async mounted() {
    this.loadPlaces();
    this.loadGroups();
    this.isNew = /create$/.test(this.$route.path);
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  validations() {
    return {
      form: {
        placeId: { required },
        products: {
          $each: {
            lot: {
              name: {},
              validity: { date, minDate: minDate() },
            },
            newValues: {
              quantity: { required, minValue: minValue(0.0001) },
              value: { required, minValue: minValue(0.0001) },
            },
          },
        },
      },
    };
  },
  methods: {
    loadPlaces() {
      this.places = [];

      const params = {
        active: true,
        limit: 0,
      };

      return this.$http
        .get('/places', { params })
        .then(({ data }) => {
          this.places = data.items;
        })
        .catch(() => {});
    },
    loadGroups() {
      this.groups = [];

      const params = {
        active: true,
        limit: 0,
      };

      return this.$http
        .get('/product-groups', { params })
        .then(({ data }) => {
          this.groups = data.items;
        })
        .catch(() => {});
    },
    load() {
      this.loading = true;
      return this.$http
        .get(`/stock-inventories/${this.form.id}`)
        .then(({ data }) => {
          data.placeId = data.place.id;
          data.groupId = data.group ? data.group.id : '';
          delete data.place;
          if (data.product) {
            data.productId = data.product.id;
            this.product = data.product;
            delete data.product;
          }
          this.form = mergeFrom(this.blankForm(), data);
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    save(finished = false) {
      if (finished && !this.canAccessFinishKit) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return false;
      }

      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return false;
      }

      if (finished) {
        this.finishing = true;
      } else {
        this.saving = true;
      }

      let productInvalid = null;

      this.form.products.forEach((item) => {
        if (item.lot && (!item.lot.name || !item.lot.validity)) {
          productInvalid = item.name;
        }
      });

      if (productInvalid) {
        this.$toast
          .show(`Informe o Lote e validade do produto: ${productInvalid}!`, { type: 'error' });
        this.saving = false;
        this.finishing = false;
        return null;
      }

      this.form.userId = this.user.id;
      const inventory = this.clone(this.form);
      inventory.status = finished ? 'finished' : 'open';

      const request = this.isNew
        ? this.$http.post('/stock-inventories', inventory)
        : this.$http.put(`/stock-inventories/${this.form.id}`, inventory);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data }) => {
          if (this.isNew) {
            this.$router.replace(`/stock/inventories/${data.id}/edit`);
            this.isNew = false;
            this.form.id = data.id;
          }
          this.form.status = finished ? 'finished' : 'open';
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .finally(() => {
          this.finishing = false;
          this.saving = false;
        });
    },
    findProduct(text) {
      const value = text.trim();

      const params = {
        types: ['material', 'medicine', 'others'],
        search: value,
      };

      return this.$http.get('/procedures', { params })
        .then(({ data }) => data.items)
        .catch(() => {});
    },
    selectProduct(product) {
      this.form.productId = product.id;
      this.product = {
        id: product.id,
        name: product.name,
        outputUnit: product.outputUnit,
        manageLot: product.manageLot,
      };
    },
    unsetProduct() {
      this.$refs.productAutocomplete.searchText = null;
      this.product = null;
      this.form.products = [];
    },
    search() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return false;
      }

      if (!this.form.groupId && !this.form.productId) {
        this.$toast.error('Obrigatório selecionar um grupo ou um profissional!');
        return false;
      }

      this.productLoading = true;

      const params = {
        placeId: this.form.placeId,
      };

      if (this.form.groupId) {
        params.groupId = this.form.groupId;
      }

      if (this.form.productId) {
        params.productId = this.form.productId;
      }

      return this.$http.get('/stock-balances', { params })
        .then(({ data }) => {
          data.items.forEach((item) => {
            let lot = null;
            if (item.product.manageLot) {
              lot = item.lot
                ? item.lot
                : { name: '', validity: '' };
            }
            this.form.products.push({
              id: item.product.id,
              name: item.product.name,
              outputUnit: item.product.outputUnit,
              manageLot: item.product.manageLot,
              lot,
              oldValues: {
                quantity: item.quantity || 0,
                value: item.cost || 0,
              },
              newValues: {
                quantity: item.quantity || 0,
                value: item.cost || 0,
              },
              lock: false,
              isNew: item.product.manageLot && !item.lot,
            });
          });
        })
        .catch(() => {})
        .finally(() => {
          this.productLoading = false;
        });
    },
    cancel() {
      this.form.products = [];
    },
    removeItem(id, i) {
      this.form.products.splice(i, 1);
    },
    openNewLot() {
      if (!this.product || !this.product.id) {
        return;
      }
      this.form.products.push({
        id: this.product.id,
        name: this.product.name,
        outputUnit: this.product.outputUnit,
        manageLot: this.product.manageLot,
        lock: false,
        isNew: true,
        lot: {
          id: '',
          name: '',
          validity: '',
        },
        oldValues: {
          quantity: '',
        },
        newValues: {
          quantity: '',
          value: '',
        },
      });
    },
    blankForm() {
      return {
        id: '',
        userId: '',
        date: moment().format('YYYY-MM-DD[T]HH:mm'),
        status: '',
        placeId: '',
        groupId: '',
        productId: '',
        notes: '',
        products: [],
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.inventory-page {
  .item-group {
    background-color: $gray-color-ultra-light;
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    margin-top: $layout-spacing;
    padding: $layout-spacing;
    .divider {
      border-top: $border-width dashed $gray-color;
    }
  }
}
</style>
